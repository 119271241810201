type Callback = (...args: unknown[]) => void;

export const debounced = (delay: number, fn: Callback): Callback => {
  let timeout: any;

  return (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      fn(...args);
    }, delay);
  };
};

export const throttled = (delay: number, fn: Callback): Callback => {
  let lastCall = 0;

  return (...args) => {
    const now = Date.now();

    if (now - lastCall < delay) return;

    lastCall = now;

    fn(...args);
  };
};
