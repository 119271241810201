export const baseColors = {
  white: "#ffffff",
  black: "#000000",
  dark: "#172b4d",
  gray: "#8898aa"
};

export const fontWeight = {
  thin: "100",
  extraLight: "200",
  light: "300",
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  extraBold: "800",
  black: "900"
};

export const breakpoints = {
  xs: 480 - 0.02,
  sm: 576 - 0.02,
  md: 768 - 0.02,
  lg: 992 - 0.02,
  xl: 1200 - 0.02,
  xxl: 1600 - 0.02
} as const;

const getMedia = <T extends number>(breakpoint: T): `(max-width: ${T}px)` => `(max-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(breakpoints.xs),
  sm: getMedia(breakpoints.sm),
  md: getMedia(breakpoints.md),
  lg: getMedia(breakpoints.lg),
  xl: getMedia(breakpoints.xl),
  xxl: getMedia(breakpoints.xxl)
};
