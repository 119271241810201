import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./store/store";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// console.log("NODE_ENV:", process.env.REACT_APP_NODE_ENV);
// console.log("SERVER_API:", process.env.REACT_APP_API_KEY);

if (process.env.REACT_APP_NODE_ENV === "development") {
  const { worker } = require("src/mocks/browser");
  worker.start();
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
