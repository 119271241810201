import { useEffect } from "react";
import NProgress from "nprogress";
import { Space, Spin, Row } from "antd";
import styled from "styled-components";
import { media } from "src/styles/themes/constants";

interface Props {
  style?: {};
}

function SuspenseLoader(props: Props) {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <LoadingStyled style={{ ...props.style }}>
      <SpinStyled size={"large"} />
    </LoadingStyled>
  );
}

export default SuspenseLoader;

const LoadingStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: -100px;
  margin-left: -100px;
  color: var(--menu-color);

  @media only screen and (${media.lg}) {
    margin-left: -30px;
  }
`;

const SpinStyled = styled(Spin)`
  .ant-spin-dot {
    font-size: 36px;
    color: var(--primary-color);
  }
  .ant-spin-text {
    font-size: 16px;
    color: var(--primary-color);
  }
`;
