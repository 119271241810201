import styled, { css } from "styled-components";
import { useEffect, FC, useState } from "react";
import { Outlet, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Layout, Menu, Typography, Card, Input, Select, DatePicker, Space, Button, Table, Row, Drawer } from "antd";
import { AppstoreOutlined, CalendarOutlined, MailOutlined } from "@ant-design/icons";
import { media, fontWeight } from "src/styles/themes/constants";

// project import
import { logoIcon, alertIcon, avatarIcon, selectIcon, settingIcon, homeIcon } from "src/images";
import { totop, totopadmin } from "src/images/admin";
import { LinkStyled, TextStyled, IconStyled, MenuStyled, ButtonPrimaryStyled } from "src/components/styles";

import type { MenuProps, MenuTheme } from "antd/es/menu";
import { useTranslation } from "react-i18next";
import { useScrollToTop, scrollToTop } from "src/hooks/useScrollToTop";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useLocationPathname from "src/hooks/useLocationPathname";
import LayoutHeader from "../header/LayoutHeader";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(label: React.ReactNode, key?: React.Key | null, children?: MenuItem[]): MenuItem {
  return {
    key,
    children,
    label
  } as MenuItem;
}

interface Props {
  hideSidebar?: boolean;
}

const MainLayout: FC<Props> = ({ hideSidebar }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const navigate = useNavigate();
  const displayed = useScrollToTop(() => window.pageYOffset > 1);
  const { locationPathname } = useLocationPathname();
  const openDrawer = () => setVisible(!visible);

  const items: MenuItem[] = [
    getItem(`${t("layout.admin.sider.package")}`, "/package"),
    getItem(`${t("layout.admin.sider.developer")}`, "/developer"),
    getItem("Map", "/map"),
    getItem(`${t("layout.admin.sider.config")}`, "/sub1", [
      getItem(`${t("layout.admin.sider.configs.loan")}`, "/config/loan"),
      getItem(`${t("layout.admin.sider.configs.customer")}`, "/config/customer"),
      getItem(`${t("layout.admin.sider.configs.loanStatus")}`, "/config/loan-status"),
      getItem(`${t("layout.admin.sider.configs.report")}`, "/config/report")
    ])
  ];

  const selectedKeysLocationPathname = (locationPathname: string) => {
    if (locationPathname.slice(0, 8) === "/package") {
      return "/package";
    }
    return locationPathname;
  };

  const defaultOpenKeysLocationPathname = (locationPathname: string) => {
    if (locationPathname.slice(0, 7) === "/config") {
      return "/sub1";
    }
    return "";
  };

  const menuSider = (
    <MenuAdminStyled
      mode="inline"
      defaultOpenKeys={[defaultOpenKeysLocationPathname(locationPathname)]}
      selectedKeys={[selectedKeysLocationPathname(locationPathname)]}
      items={items}
      onClick={({ key }) => {
        navigate(key);
        setVisible(false);
      }}
    />
  );

  const drawer = (
    <Drawer
      title={false}
      placement={placement === "right" ? "left" : "right"}
      closable={false}
      onClose={() => setVisible(false)}
      open={visible}
      key={placement === "right" ? "left" : "right"}
      width={330}
      className="drawer-sidebar"
      style={{ background: "var(--background-color)" }}
    >
      <Layout className="layout-dashboard" style={{ background: "var(--background-color)" }}>
        <Layout.Sider
          trigger={null}
          width={280}
          className="sider-primary ant-layout-sider-primary"
          style={{ background: "var(--background-color)" }}
        >
          {menuSider}
        </Layout.Sider>
      </Layout>
    </Drawer>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <LayoutHeader onClick={openDrawer} />
      <LayoutCustomStyled>
        {drawer}
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {}}
          trigger={null}
          width={280}
          className="sider-primary ant-layout-sider-primary"
          style={{ background: "initial", display: hideSidebar ? "none" : "block" }}
        >
          {menuSider}
        </Layout.Sider>
        <LayoutContentCustomStyled>
          <Outlet />
        </LayoutContentCustomStyled>
      </LayoutCustomStyled>
      {displayed && (
        <img
          src={totopadmin}
          style={{ position: "fixed", bottom: 12, right: 12, cursor: "pointer", width: "40px" }}
          onClick={scrollToTop}
        />
      )}
    </Layout>
  );
};

export default MainLayout;

const LayoutStyled = styled(Layout)``;
const LayoutCustomStyled = styled(Layout)`
  height: 100%;
  background: var(--background-color);
  padding: 32px 24px 0px 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const DivCustomStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 16px;
  border-radius: 50px;
  margin-bottom: 16px;
  background: #dee7ed;
`;

const LayoutContentCustomStyled = styled(Layout.Content)`
  background: var(--background-color);
  padding-left: 24px;
  padding-bottom: 24px;
  @media only screen and (${media.lg}) {
    padding-left: 0px;
  }
`;

const MenuAdminStyled = styled(MenuStyled)`
  &.ant-menu-inline .ant-menu-submenu-title {
    position: relative;
    padding-inline: 16px;
    border-radius: 21px;
  }
  &.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background: var(--menu-color);
    color: var(--white-color);
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    border-radius: 21px;
    padding-inline: 30px;
  }
  .ant-menu-inline .ant-menu-item {
    width: auto;
    padding-left: 24px !important;
  }
  .ant-menu-submenu-inline {
    margin-block: 10px;
  }
`;
