import { Card } from "antd";
import styled from "styled-components";

export const CardStyled = styled(Card)`
  &.ant-card {
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    border-radius: 10px;
    margin-bottom: 25px;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px; //default
  }
`;
export const CardInsideStyled = styled(Card)`
  &.ant-card {
    box-shadow: 0px 0px 32px rgba(136, 152, 170, 0.15);
    border-radius: 10px;
  }
  .ant-card-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px; //default
  }
`;
export const CardNormalStyled = styled(Card)`
  &.ant-card {
    border-radius: 10px;
  }
  &.ant-card .ant-card-body {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px; //default
  }
`;
