import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RestOutlined, SearchOutlined } from "@ant-design/icons";
import { Space, InputRef } from "antd";
import { debounce } from "lodash";
import { Delays } from "src/utils/constants";
import { ButtonPrimaryStyled, InputPrefixStyled } from "src/components/styles";

export const SearchFilterDropdown = ({
  placeholder,
  confirm,
  onSearch,
  close,
  searchInputRef
}: {
  placeholder: string;
  confirm: () => void;
  onSearch: (value: string) => void;
  close: any;
  searchInputRef: React.RefObject<InputRef>;
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  const debounced = React.useRef(
    debounce((confirm) => {
      confirm();
    }, Delays.search)
  ).current;

  useEffect(() => {
    debounced(confirm);
  }, [search]);

  const handleChange = (value: string) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      <ContentStyled>
        <InputPrefixStyled
          ref={searchInputRef}
          prefix={<SearchOutlined />}
          placeholder={placeholder}
          value={search}
          onChange={(e) => handleChange(e.target.value)}
          onPressEnter={close}
          style={{ width: 300 }}
        />
      </ContentStyled>
      <BottomStyled>
        <Space>
          <ButtonPrimaryStyled color="var(--cancel-color)" icon={<RestOutlined />} onClick={() => handleChange("")}>
            {t("common.reset")}
          </ButtonPrimaryStyled>
          <ButtonPrimaryStyled onClick={close}>{t("common.confirm")}</ButtonPrimaryStyled>
        </Space>
      </BottomStyled>
    </div>
  );
};

const ContentStyled = styled.div`
  padding: 8px 10px;
  border-top: 1px solid #e9ecef;
`;
const BottomStyled = styled.div`
  display: flex;
  justify-content: end;
  background-color: #f7fafc;
  padding: 12px;
  border-radius: 0 0 10px 10px;
`;
