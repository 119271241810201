import { Breadcrumb } from "antd";
import { fontWeight } from "src/styles/themes/constants";
import styled from "styled-components";

export const BreadcrumbStyled = styled(Breadcrumb).attrs(() => ({
  separator: "-"
}))`
  li {
    align-self: center;
  }
  li > .ant-breadcrumb-link > a {
    color: var(--primary-color);
    text-decoration: underline;
  }
  li:last-child > .ant-breadcrumb-link {
    color: var(--title-color);
    font-size: 20px;
    font-weight: ${fontWeight.semibold};
  }
`;

