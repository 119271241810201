import styled from "styled-components";
import { DatePicker } from 'antd';

export const DateStyled = styled(DatePicker)`
  height: 39px;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  color: var(--input-color);
  background: var(--white-color);
  &.ant-picker-disabled {
    cursor: default;
    background: var(--white-color);
  }
  &.ant-picker .ant-picker-input >input[disabled] {
    color: var(--input-color);
    cursor: default;
  }
`;
