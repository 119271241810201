import { Radio } from "antd";
import styled from "styled-components";

export const RadioGroupStyled = styled(Radio.Group)`
  .ant-radio-wrapper,
  .ant-radio-wrapper-disabled {
    color: var(--label-color);
    cursor: default;
  }
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .ant-radio-wrapper-disabled .ant-radio-checked .ant-radio-inner {
    border-color: var(--gray-color);
    background-color: rgba(94, 114, 228, 0.7);
  }
  .ant-radio-wrapper .ant-radio-disabled .ant-radio-inner::after {
    background-color: var(--white-color);
  }
  .ant-radio-wrapper .ant-radio-disabled,
  .ant-radio-wrapper .ant-radio-disabled .ant-radio-input {
    cursor: default;
  }
`;