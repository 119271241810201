import { Checkbox } from "antd";
import styled from "styled-components";

export const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  .ant-checkbox .ant-checkbox-input {
    color: var(--primary-color);
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-color);
  }
`;