import { createListenerMiddleware } from "@reduxjs/toolkit";
import { authApi } from "src/store/services/authApi";

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: authApi.endpoints.login.matchFulfilled,
  effect: async (action, listenerApi) => {
    listenerApi.cancelActiveListeners();

    if (action.payload.data.token) {
      localStorage.setItem("token", action.payload.data.token);
    }
  }
});
