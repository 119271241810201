import { Popover, PopoverProps } from "antd";
import styled from "styled-components";
import { CheckboxStyled } from "./Checkbox";

export const PopoverCheckboxStyled = styled(CheckboxStyled)`
  .ant-checkbox + span {
    padding-inline-start: 20px;
    padding-inline-end: 8px;
  }
`;
const TitleStyled = styled.div`
  padding: 0 16px 8px;
`;
const ContentStyled = styled.div`
  padding: 0 0 8px 0;
  ${CheckboxStyled} {
    min-width: 355px;
    padding: 8px 7px;
    margin: 4px 9px;
    border-radius: 10px;
    .ant-checkbox + span {
      padding-inline-start: 60px;
      width: 100%;
    }
  }
  .ant-checkbox-wrapper-checked {
    background-color: rgba(94, 114, 228, 0.07);
  }
`;
const BottomStyled = styled.div`
  display: flex;
  justify-content: end;
  background-color: #f7fafc;
  padding: 12px;
  border-radius: 0 0 10px 10px;
`;
interface PopoverStyledProps extends PopoverProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
  bottom?: React.ReactNode;
  split?: boolean;
}
const PopoverStyled = styled(({ children, title, content, bottom, split = true, ...props }: PopoverStyledProps) => {
  return (
    <Popover
      arrow={false}
      placement="bottomLeft"
      trigger="click"
      overlayInnerStyle={{ padding: "12px 0 0", minWidth: 300 }}
      {...props}
      title={!!title ? <TitleStyled style={{ borderBottom: split ? " 1px solid #e9ecef" : "none" }}>{title}</TitleStyled> : null}
      content={
        <>
          {!!content && <ContentStyled>{content}</ContentStyled>}
          {!!bottom && <BottomStyled>{bottom}</BottomStyled>}
        </>
      }
    >
      {children}
    </Popover>
  );
})``;

export default PopoverStyled;
