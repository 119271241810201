import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { listenerMiddleware } from "src/store/middleware/ListenerMiddleware";
import { api } from "src/store/services/api";
import { authApi } from "src/store/services/authApi";
import auth from "src/store/slice/authSlice";
export const store = configureStore({
  reducer: {
    auth,
    [api.reducerPath]: api.reducer
  },
  middleware: (getDefault) => getDefault().concat([api.middleware]).prepend(listenerMiddleware.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
