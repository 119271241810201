import styled from "styled-components";
import { Checkbox, Button, Input, Form } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import loginBackground from "src/assets/images/login-bg.webp";
import authBackground from "src/images/admin/authBackground.png";
import { media } from "src/styles/themes/constants";

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
`;

export const BackgroundWrapper = styled.div`
  min-width: 40%;
  height: 100%;
  background: url(${authBackground});
  background-size: cover;
  position: relative;
  opacity: 1;
  @media only screen and (${media.lg}) {
    min-width: 0;
    display: none;
  }
`;
export const InBackgroundWrapper = styled.div`
  min-width: 40%;
  height: 100%;
  background: linear-gradient(89.96deg, #E45E5E 11.99%, #E47F5F 99.96%);
  /* background-size: cover; */
  position: relative;
  opacity:0.4;
  @media only screen and (${media.lg}) {
    min-width: 0;
    display: none;
  }
`;
export const InBackgroundWrapper2 = styled.div`
  min-width: 40%;
  height: 100%;
background: linear-gradient(90deg, rgba(135, 57, 85, 0.5) 0%, rgba(195, 176, 176, 0.5) 100%);
  /* background-size: cover; */
  position: relative;
  opacity:0.4;
  @media only screen and (${media.lg}) {
    min-width: 0;
    display: none;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
;
`;
