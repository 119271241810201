import { createApi, fetchBaseQuery, retry, BaseQueryFn, FetchArgs, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

import { RootState } from "src/store/store";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_KEY,
  prepareHeaders: (headers, { getState }) => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).auth?.user?.data?.token || localStorage.getItem("token");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/auth/login";
  }
  return result;
};

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 1 });

export const api = createApi({
  reducerPath: "splitApi",
  baseQuery: baseQueryWithRetry,
  refetchOnMountOrArgChange: true,
  endpoints: () => ({})
});
