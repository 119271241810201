import { ITheme } from "../../models/theme/itheme.model";

export const lightColorsTheme: ITheme = {
  background: "#f8f9fe",
  header: "linear-gradient(90deg, #E1335D 0%, #F06F53 100%)",
  login: "linear-gradient(89.96deg, #5e8ce4 11.99%, #705fe4 99.96%)",
  menu: "linear-gradient(90deg, #E1335D 0%, #F06F53 100%)",
  pale:'linear-gradient(90deg, #F29CAC 0%, #F5B4A1 100%)',
  primary: "#EF6C54",
  title: "#32325d",
  placeholder: "#bdbdbd",
  border: "#dee2e6",
  label: "#525f7f",
  input: "#525f7f",
  inputSelection: "#8898aa",
  inputIcon: "#8898aa",
  cancel: "#E5E9ED",
};
