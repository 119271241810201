import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import * as S from "./AuthLayout.styles";
import { IconStyled } from "src/components/styles";
import iconframeworkauth from "src/images/admin/iconframeworkauth.svg";
import { Row, Col } from "antd";

const AuthLayout: React.FC = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 992);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <S.Wrapper>
        {mobile ? (
          <></>
        ) : (
          <S.BackgroundWrapper>
            <S.InBackgroundWrapper />
            <S.InBackgroundWrapper2 />
            <S.LoginWrapper>
              <IconStyled src={iconframeworkauth} />
            </S.LoginWrapper>
          </S.BackgroundWrapper>
        )}

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
          <Outlet />
        </div>
      </S.Wrapper>
    </>
  );
};

export default AuthLayout;
