import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationTH from "src/components/locales/th/translation.json";
import translationEN from "src/components/locales/en/translation.json";

const resources = {
  th: {
    translation: translationTH
  },
  eng: {
    translation: translationEN
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "th",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
