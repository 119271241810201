import styled, { css } from "styled-components";
import { useEffect, FC, useState } from "react";
import { Outlet, Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Layout, Menu, Typography, Card, Input, Select, DatePicker, Space, Button, Table, Row, Drawer } from "antd";
import { AppstoreOutlined, CalendarOutlined, MailOutlined, LeftOutlined } from "@ant-design/icons";
import { media, fontWeight } from "src/styles/themes/constants";

// project import
import { logoIcon, alertIcon, avatarIcon, selectIcon, settingIcon, homeIcon } from "src/images";
import { totop, totopadmin } from "src/images/admin";
import { LinkStyled, TextStyled, IconStyled, MenuStyled, ButtonPrimaryStyled } from "src/components/styles";

import type { MenuProps, MenuTheme } from "antd/es/menu";
import LayoutHeader from "src/components/layouts/header/LayoutHeader";
import { useTranslation } from "react-i18next";
import { useScrollToTop, scrollToTop } from "src/hooks/useScrollToTop";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useLocationPathname from "src/hooks/useLocationPathname";
import { useDevelopersQuery } from "src/store/services/developer/developerApi";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(label: React.ReactNode, key?: React.Key | null, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
  return {
    key,
    icon,
    children,
    label
  } as MenuItem;
}

interface Props {
  hideSidebar?: boolean;
}

const DeveloperLayout: FC<Props> = ({ hideSidebar }: Props) => {
  const { t } = useTranslation();
  const { developerId } = useParams();
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  // const location = useLocation();
  const navigate = useNavigate();
  // const { data: profile } = useProfile();
  // const { profilePicture, profilePictureIsSuccess, profilePictureIsFetching } = useProfilePicture(profile?.user.profile_id);
  const displayed = useScrollToTop(() => window.pageYOffset > 1);
  const { locationPathname } = useLocationPathname();
  const openDrawer = () => setVisible(!visible);
  const { data: developers } = useDevelopersQuery();
  const siteUrl = developers?.data.find((x: any) => x?.developer_code === developerId)?.dev_api_url;
  // const siteUrl = "http://10.1.0.4:6003";
  // const siteUrl = "https://api-sansiri-dev.icondigitalgateway.com/v2";

  const items: MenuItem[] = [
    getItem(t("common.back"), "/developer", <LeftOutlined />),
    getItem(t("layout.admin.sider.package"), `/developer/${developerId}/package`),
    getItem(t("common.edit"), `/developer/${developerId}/edit`),
    getItem(t("page.admin.developer.user.title"), `/developer/${developerId}/user`),
    getItem(t("layout.admin.sider.masterSetting"), "/sub1", null, [
      getItem(t("layout.admin.sider.masterSettingProvince"), `/developer/${developerId}/master-setting/province`),
      getItem(t("layout.admin.sider.masterSettingDistrict"), `/developer/${developerId}/master-setting/district`),
      getItem(t("layout.admin.sider.masterSettingSubDistrict"), `/developer/${developerId}/master-setting/sub-district`),
      getItem(t("layout.admin.sider.masterSettingPrefix"), `/developer/${developerId}/master-setting/prefix`),
      getItem(t("layout.admin.sider.masterSettingGender"), `/developer/${developerId}/master-setting/gender`),
      getItem(t("layout.admin.sider.masterSettingCardType"), `/developer/${developerId}/master-setting/card-type`),
      getItem(t("layout.admin.sider.masterSettingNationality"), `/developer/${developerId}/master-setting/nationality`),
      getItem(t("layout.admin.sider.masterSettingMarriedStatus"), `/developer/${developerId}/master-setting/married-status`),
      getItem(t("layout.admin.sider.masterSettingEducation"), `/developer/${developerId}/master-setting/education`),
      getItem(t("layout.admin.sider.masterSettingOccupation"), `/developer/${developerId}/master-setting/occupation`),
      getItem(t("layout.admin.sider.masterSettingPosition"), `/developer/${developerId}/master-setting/position`),
      getItem(t("layout.admin.sider.masterSettingBusiness"), `/developer/${developerId}/master-setting/business`),
      getItem(t("layout.admin.sider.masterSettingWelfare"), `/developer/${developerId}/master-setting/welfare`),
      getItem(t("layout.admin.sider.masterSettingBureau"), `/developer/${developerId}/master-setting/bureau`),
      getItem(t("layout.admin.sider.masterSettingBank"), `/developer/${developerId}/master-setting/bank`),
      getItem(t("layout.admin.sider.masterSettingLoanStatus"), `/developer/${developerId}/master-setting/loan-status-developer`),
      getItem(t("layout.admin.sider.masterSettingCollateral"), `/developer/${developerId}/master-setting/collateral`),
      getItem(t("layout.admin.sider.masterSettingCountry"), `/developer/${developerId}/master-setting/country`),
      getItem(t("layout.admin.sider.masterSettingRelationship"), `/developer/${developerId}/master-setting/relationship`)
    ])
  ];
  const defaultOpenKeysLocationPathname = (locationPathname: string) => {
    if (locationPathname.slice(locationPathname.lastIndexOf("/") - 15, locationPathname.lastIndexOf("/")) === "/master-setting") {
      return "/sub1";
    }
    return "";
  };
  const selectedKeysPathname = (locationPathname: any) => {
    let result = locationPathname;
    let locationPathnameLastIndexOf = locationPathname.substring(locationPathname.lastIndexOf("/") + 1);
    if (
      locationPathnameLastIndexOf === "loan" ||
      locationPathnameLastIndexOf === "loan-status" ||
      locationPathnameLastIndexOf === "customer" ||
      locationPathnameLastIndexOf === "report"
    ) {
      result = `/developer/${developerId}/package`;
    }
    return result;
  };

  const menuSider = (
    <MenuAdminStyled
      mode="inline"
      defaultOpenKeys={[defaultOpenKeysLocationPathname(locationPathname)]}
      selectedKeys={[selectedKeysPathname(locationPathname)]}
      items={items}
      onClick={({ key }) => {
        navigate(key);
        setVisible(false);
      }}
    />
  );

  const drawer = (
    <Drawer
      title={false}
      placement={placement === "right" ? "left" : "right"}
      closable={false}
      onClose={() => setVisible(false)}
      open={visible}
      key={placement === "right" ? "left" : "right"}
      width={330}
      className="drawer-sidebar"
      style={{ background: "var(--background-color)" }}
    >
      <Layout className="layout-dashboard" style={{ background: "var(--background-color)" }}>
        <Layout.Sider
          trigger={null}
          width={280}
          className="sider-primary ant-layout-sider-primary"
          style={{ background: "var(--background-color)" }}
        >
          {menuSider}
        </Layout.Sider>
      </Layout>
    </Drawer>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <LayoutHeader
        onClick={openDrawer}
        // profile={profile}
        // profilePicture={profilePicture}
        // profilePictureIsSuccess={profilePictureIsSuccess}
        // profilePictureIsFetching={profilePictureIsFetching}
      />
      <LayoutCustomStyled>
        {drawer}
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          onCollapse={(collapsed, type) => {}}
          trigger={null}
          width={280}
          className="sider-primary ant-layout-sider-primary"
          style={{ background: "initial", display: hideSidebar ? "none" : "block" }}
        >
          {menuSider}
        </Layout.Sider>
        <LayoutContentCustomStyled>
          <Outlet
            // context={{ profile, profilePicture, profilePictureIsSuccess, profilePictureIsFetching }}
            context={{ siteUrl }}
          />
        </LayoutContentCustomStyled>
      </LayoutCustomStyled>
      {displayed && (
        <img
          src={totopadmin}
          style={{ position: "fixed", bottom: 12, right: 12, cursor: "pointer", width: "40px" }}
          onClick={scrollToTop}
        />
      )}
    </Layout>
  );
};

export default DeveloperLayout;

const LayoutStyled = styled(Layout)``;
const LayoutCustomStyled = styled(Layout)`
  height: 100%;
  background: var(--background-color);
  padding: 32px 24px 0px 24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

const DivCustomStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 16px;
  border-radius: 50px;
  margin-bottom: 16px;
  background: #dee7ed;
`;

const LayoutContentCustomStyled = styled(Layout.Content)`
  background: var(--background-color);
  padding-left: 24px;
  padding-bottom: 24px;
  @media only screen and (${media.lg}) {
    padding-left: 0px;
  }
`;

const MenuAdminStyled = styled(MenuStyled)`
  &.ant-menu-inline .ant-menu-submenu-title {
    position: relative;
    padding-inline: 16px;
    border-radius: 21px;
  }
  &.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background: var(--menu-color);
    color: var(--white-color);
  }
  &.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    border-radius: 21px;
    padding-inline: 30px;
  }
  .ant-menu-inline .ant-menu-item {
    width: auto;
    padding-left: 24px !important;
  }
  .ant-menu-submenu-inline {
    margin-block: 10px;
  }
`;
