import { useEffect, useState } from "react";
import { debounced } from "src/utils/utils";

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
const isFullPageScrolled = () => window.pageYOffset > document.documentElement.clientHeight;

export const useScrollToTop = (shouldDisplay = isFullPageScrolled): boolean => {
  const [displayed, setDisplayed] = useState(false);

  useEffect(() => {
    const onScroll = debounced(100, () => {
      setDisplayed(shouldDisplay());
    });
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [shouldDisplay]);

  return displayed;
};
