import { css } from "styled-components";
import { lightColorsTheme } from "./lightTheme";
import { baseColors } from "./constants";

type ThemeType = "light" | "dark";

export const themeObject = {
  light: lightColorsTheme,
  dark: lightColorsTheme
};

const getThemeVariables = (theme: ThemeType) => css`
  color-scheme: ${theme};
  --background-color: ${themeObject[theme].background};
  --menu-color: ${themeObject[theme].menu};
  --pale-color:${themeObject[theme].pale};
  --header-color: ${themeObject[theme].header};
  --login-color: ${themeObject[theme].login};
  --primary-color: ${themeObject[theme].primary};
  --title-color: ${themeObject[theme].title};
  --placeholder-color: ${themeObject[theme].placeholder};
  --border-color: ${themeObject[theme].border};
  --label-color: ${themeObject[theme].label};
  --input-color: ${themeObject[theme].input};
  --input-selection-color: ${themeObject[theme].inputSelection};
  --input-icon-color: ${themeObject[theme].inputIcon};
  --cancel-color: ${themeObject[theme].cancel};
`;

export const lightThemeVariables = css`
  ${getThemeVariables("light")}
`;

export const commonThemeVariables = css`
  color-scheme: light dark;
  --white-color: ${baseColors.white};
  --black-color: ${baseColors.black};
  --dark-color: ${baseColors.dark};
  --gray-color: ${baseColors.gray};
`;
