import { api } from "src/store/services/api";
import { User } from "src/models/api/auth.model";

export type UserData = Omit<User, "id">;
type ResponseLoginData = User & { response: any; data: { token: string } };

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ResponseLoginData, UserData>({
      query: (userData) => ({
        url: "auth/login",
        method: "POST",
        body: userData
      })
    }),
    fetchToken: builder.query<any, void>({
      query: () => ({
          url: 'package',
          method: "POST",
      }),
    }),
    logout: builder.mutation<void, string>({
      query: (username) => ({
        url: "auth/logout",
        method: "POST",
        body: { username: username }
      })
    })
  })
});

export const { useLoginMutation, useLogoutMutation, useFetchTokenQuery } = authApi;

export const {
  endpoints: { login, logout, fetchToken }
} = authApi;
