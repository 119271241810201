import { api } from "src/store/services/api";

export const developerApi = api.enhanceEndpoints({ addTagTypes: ["Developer"] }).injectEndpoints({
  endpoints: (builder) => ({
    developers: builder.query<any, void>({
      query: () => ({
        url: "developer",
        method: "POST",
      }),
      providesTags: ["Developer"]
    }),
    addDeveloper: builder.mutation<any, any>({
      query: (body) => ({
        url: "developer/add",
        method: "POST",
        body
      }),
      invalidatesTags: ["Developer"]
    }),
    editDeveloper:builder.mutation<any,any>({
      query: (body) => ({
        url: "developer/edit",
        method: "POST",
        body
      }),
      invalidatesTags: ["Developer"]
    }),
    setActiveDeveloper:builder.mutation<any,any>({
      query: (body) => ({
        url: "developer/set-active",
        method: "POST",
        body
      }),
      invalidatesTags: ["Developer"]
    }),
    
  })
});

export const { useDevelopersQuery ,useAddDeveloperMutation,useEditDeveloperMutation,useSetActiveDeveloperMutation, } = developerApi;
