import { css } from "styled-components";

export const sidebarToggler = css`
  .ant-drawer.drawer-sidebar.drawer-sidebar-rtl aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
    left: auto;
    right: 0px;
    direction: rtl;
  }

  .ant-drawer.drawer-sidebar.drawer-sidebar-rtl aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary {
    left: auto;
    right: 0px;
    direction: rtl;
  }

  aside.ant-layout-sider.ant-layout-sider-light.sider-primary.ant-layout-sider-primary.active-route a.active {
    background: transparent;
    box-shadow: none;
  }

  @media (min-width: 992px) {
    .card-billing-info.ant-card .ant-card-body {
      display: flex;
    }
    .layout-dashboard-rtl {
      overflow: auto;
    }
    .layout-dashboard-rtl .ant-layout-sider.sider-primary {
      margin: 20px 20px 0 0;
      height: calc(100vh - 20px);
    }
    .layout-dashboard-rtl .ant-layout-sider.sider-primary {
      right: 0;
      left: auto;
    }
    .layout-dashboard-rtl .ant-layout-sider.sider-primary {
      right: 0;
      left: auto;
    }
    .layout-dashboard-rtl .ant-layout {
      margin-right: 280px;
      margin-left: 0;
    }
    .layout-dashboard-rtl .ant-layout-footer {
      margin: 0 20px 20px 0;
    }
  }

  @media (min-width: 992px) {
    .layout-dashboard .ant-layout-footer {
      margin: 0 0 20px 20px;
    }
    .layout-dashboard .ant-layout-sider.sider-primary {
      /* margin: 20px 0 0 20px; */
      /* padding: 13px 20px; */
      height: calc(100vh - 20px);
    }
    .layout-dashboard .ant-layout {
      width: auto;
      flex-shrink: 1;
      margin-left: 280px;
    }
    .layout-dashboard .header-control .sidebar-toggler {
      display: none;
    }
    .sidebar-toggler {
      display: none;
    }
  }
`;
