import { useMemo } from "react";
import { useLoginMutation, useLogoutMutation, useFetchTokenQuery } from "src/store/services/authApi";

const useAuth = () => {
  const [login, loginResult] = useLoginMutation();
  const [logout, logoutResult] = useLogoutMutation();
  const { data: fetchToken, isLoading: tokenIsLoading } = useFetchTokenQuery();

  return useMemo(() => {
    return {
      login,
      loginResult,
      logout,
      logoutResult,
      fetchToken,
      tokenIsLoading
    };
  }, [login, loginResult, logout, logoutResult, fetchToken, tokenIsLoading]);
};

export default useAuth;
