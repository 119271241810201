import styled, { css } from "styled-components";
import { Select } from "antd";

export const SelectStyled = styled(Select)`
  .ant-select-selector {
    height: 39px !important;
    border-radius: 10px;
  }
  .ant-select-selection-placeholder {
    line-height: 38px !important;
  }
`;

export const SelectOptionStyled = styled(Select.Option)``;
