import styled from "styled-components";
import { Tabs } from "antd";
import { fontWeight, media } from "src/styles/themes/constants";

export const TabsStyled = styled(Tabs)`
    .ant-tabs-nav {
        padding-top:10px;
        padding-left: 10px;
        background: #F7FAFC;
        margin-left: -24px;
        margin-right: -24px;
      
    }
    &.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
        border: hidden;
    }
    &.ant-tabs-top >.ant-tabs-nav::before {
        border-bottom: hidden;
    }

    &.ant-tabs-card >.ant-tabs-nav .ant-tabs-tab {
        background: rgba(0, 0, 0, 0);
    }
   
    
    &.ant-tabs-card.ant-tabs-top >.ant-tabs-nav .ant-tabs-tab-active {
        background: var(--white-color) ;
        border-radius: 20px 20px 0px 0px;
    }
`