import { ComponentProps, useCallback, MouseEvent } from "react";
import { useActionPending } from "@huse/action-pending";
import { Button } from "antd";
import { ButtonPrimaryStyled } from "src/components/styles";
import { SaveOutlined } from "@ant-design/icons";

type AsyncTask = () => Promise<any>;

interface Props extends ComponentProps<typeof Button> {
  task: AsyncTask;
}

const AsyncLoadingButton = ({ task, onClick, ...props }: any) => {
  const [startTask, pendingCount] = useActionPending(task);
  const click = useCallback(
    // async (e: MouseEvent<HTMLElement>) => {
    async (e: any) => {
      if (onClick) {
        await startTask();
        await onClick(e);
      } else {
        await startTask();
      }
    },
    [onClick, startTask]
  );

  return <ButtonPrimaryStyled {...props} loading={!!pendingCount} onClick={click} />;
};

export default AsyncLoadingButton;
