import React, { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import type { MenuProps } from "antd";
import { Layout, Menu, Typography, Card, Input, Select, DatePicker, Space, Button, Table, Row, Dropdown, Avatar, Col } from "antd";
import { MenuOutlined, UserOutlined, SearchOutlined, DownOutlined, SmileOutlined } from "@ant-design/icons";

// project import
import { logoIcon, alertIcon, avatarIcon, selectIcon, settingIcon, homeIcon, pen, signout, pro, down } from "src/images";
import { logoAdminIcon, alertAdminIcon } from "src/images/admin";
import { LinkStyled, TextStyled, IconStyled, MenuStyled, ButtonPrimaryStyled } from "src/components/styles";

import { LanguagePicker } from "src/components/common/LanguagePicker/LanguagePicker";
import { useTranslation } from "react-i18next";
import { fontWeight, media } from "src/styles/themes/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Notifications from "src/components/common/Notications/Notifications";
import UserMenu from "src/components/common/UserMenu/UserMenu";
import SuspenseLoader from "src/components/suspenseLoader";
import { useLanguage } from "src/hooks/useLanguage";
import { skipToken } from "@reduxjs/toolkit/query/react";

const LayoutHeader = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobile, setMobile] = useState(window.innerWidth <= 992);
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 992);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      <HeaderCustomStyled>
        <HeaderColorStyled />
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "75px", width: "100%", zIndex: 999 }}>
          {mobile ? (
            <></>
          ) : (
            <SpaceCustomStyled size={40}>
              <LinkStyled to="/package">
                <IconStyled src={logoAdminIcon} alt="logoAdminIcon" style={{ width: "160px", marginTop: "-2px" }} />
              </LinkStyled>
            </SpaceCustomStyled>
          )}
          <Button className="sidebar-toggler" type="link" onClick={props.onClick}>
            <MenuOutlinedCustomStyled />
          </Button>
          <Space size={20}>
            <UserMenu>
              <LinkStyled to={"#"}>
                <Space direction={"horizontal"}>
                  <>
                    <Avatar style={{ background: "var(--menu-color)" }} icon={<UserOutlined className="small_icon" />} />
                  </>
                </Space>
              </LinkStyled>
            </UserMenu>
            <LanguagePicker />
          </Space>
        </div>
      </HeaderCustomStyled>
    </>
  );
};

export default LayoutHeader;

const HeaderCustomStyled = styled(Layout.Header)`
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  position: relative;
  height: 75px;
  width: 100%;
  background: var(--white-color);
  color: var(--dark-color);
`;

const HeaderColorStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 75px;
  width: 100%;
  background-image: var(--header-color);
  opacity: 0.2;
  z-index: 100px;
`;

const SpaceCustomStyled = styled(Space)`
  display: flex;
  overflow: hidden;
  @media only screen and (${media.lg}) {
    display: none;
  }
`;
const MenuOutlinedCustomStyled = styled(MenuOutlined)`
  & * {
    font-size: 26px;
    margin: -2px 0 0 -20px;
    color: var(--dark-color);
  }
`;
