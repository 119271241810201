import styled from "styled-components";

export const IconStyled = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButtonStyled = styled.a`
  color: var(--input-color);
  transition: ease-in-out 200ms;
  :hover {
    color: var(--primary-color);
  }
  ${IconStyled} {
    padding: 2px;
    transition: ease-in-out 200ms;
    :hover:not(:active) {
      opacity: 0.5;
    }
  }
`;
