import { Suspense, lazy } from "react";
import { useRoutes, Navigate, Outlet } from "react-router-dom";
import { AuthChildren } from "src/components/common/AuthChildren/AuthChildren";
import MainLayout from "src/components/layouts/main/MainLayout";
import AuthLayout from "src/components/layouts/auth/AuthLayout";
import SuspenseLoader from "src/components/suspenseLoader";
import DeveloperLayout from "src/components/layouts/developer/DeveloperLayout";

// ----------------------------- loader ----------------------------------//
const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const LazyLoader = (promise: Promise<any>, time?: number) =>
  Loader(
    lazy(async () => {
      await new Promise((resolve) => setTimeout(resolve, time ?? 0));
      return promise;
    })
  );

const Packages = LazyLoader(import("src/pages/main/package/Package"));
const Map = LazyLoader(import("src/pages/main/map/Map"));
const LayoutEditPackage = LazyLoader(import("src/pages/main/package/LayoutEditPackage"));
const EditPackageLoan = LazyLoader(import("src/pages/main/package/EditPackageLoan"));
const EditPackageCustomer = LazyLoader(import("src/pages/main/package/EditPackageCustomer"));
const EditPackageLoanStatus = LazyLoader(import("src/pages/main/package/EditPackageLoanStatus"));
const EditPackageReport = LazyLoader(import("src/pages/main/package/EditPackageReport"));

const Developer = LazyLoader(import("src/pages/main/developer/Developer"));
const DeveloperPackage = LazyLoader(import("src/pages/main/developer/package/DeveloperPackage"));
const LayoutSetDeveloperPackage = LazyLoader(import("src/pages/main/developer/package/LayoutSetDeveloperPackage"));
const SetDeveloperPackageLoan = LazyLoader(import("src/pages/main/developer/package/SetDeveloperPackageLoan"));
const SetDeveloperPackageCustomer = LazyLoader(import("src/pages/main/developer/package/SetDeveloperPackageCustomer"));
const SetDeveloperPackageLoanStatus = LazyLoader(import("src/pages/main/developer/package/SetDeveloperPackageLoanStatus"));
const SetDeveloperPackageReport = LazyLoader(import("src/pages/main/developer/package/SetDeveloperPackageReport"));
const SetDeveloperUser = LazyLoader(import("src/pages/main/developer/SetDeveloperUser"));
const SetDeveloperEdit = LazyLoader(import("src/pages/main/developer/SetDeveloperEdit"));
const MasterSetting1Province = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting1Province"));
const MasterSetting2District = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting2District"));
const MasterSetting3SubDistrict = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting3SubDistrict"));
const MasterSetting4Prefix = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting4Prefix"));
const MasterSetting5Gender = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting5Gender"));
const MasterSetting6CardType = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting6CardType"));
const MasterSetting7Nationality = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting7Nationality"));
const MasterSetting8MarriedStatus = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting8MarriedStatus"));
const MasterSetting9Education = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting9Education"));
const MasterSetting10Occupation = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting10Occupation"));
const MasterSetting11Position = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting11Position"));
const MasterSetting12Business = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting12Business"));
const MasterSetting13Welfare = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting13Welfare"));
const MasterSetting14Bureau = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting14Bureau"));
const MasterSetting15Bank = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting15Bank"));
const MasterSetting16LoanStatus = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting16LoanStatus"));
const MasterSetting17Collateral = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting17Collateral"));
const MasterSetting18Country = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting18Country"));
const MasterSetting19Relationship = LazyLoader(import("src/pages/main/developer/masterSetting/MasterSetting19Relationship"));

const ConfigLoan = LazyLoader(import("src/pages/main/configs/ConfigLoan"));
const ConfigCustomer = LazyLoader(import("src/pages/main/configs/ConfigCustomer"));
const ConfigLoanStatus = LazyLoader(import("src/pages/main/configs/ConfigLoanStatus"));
const ConfigReport = LazyLoader(import("src/pages/main/configs/ConfigReport"));

// ----------------------------- auth ----------------------------------//
const LoginForm = LazyLoader(import("src/pages/auth/LoginForm"));

// ----------------------------- main-routes ----------------------------------//
const protectedLayout = (
  <AuthChildren>
    <MainLayout />
  </AuthChildren>
);
const protectedDeveloperLayout = (
  <AuthChildren>
    <DeveloperLayout />
  </AuthChildren>
);
const MainRoutes = {
  path: "",
  element: protectedLayout,
  children: [
    { index: true, element: <Navigate to="package" /> },
    { path: "package", element: <Packages /> },
    { path: "map", element: <Map /> },
    {
      path: "package/:id",
      element: <LayoutEditPackage />,
      children: [
        { index: true, element: <Navigate to="loan" /> },
        { path: "loan", element: <EditPackageLoan /> },
        { path: "customer", element: <EditPackageCustomer /> },
        { path: "loan-status", element: <EditPackageLoanStatus /> },
        { path: "report", element: <EditPackageReport /> }
      ]
    },
    { path: "developer", element: <Developer /> },
    { path: "config/loan", element: <ConfigLoan /> },
    { path: "config/customer", element: <ConfigCustomer /> },
    { path: "config/loan-status", element: <ConfigLoanStatus /> },
    { path: "config/report", element: <ConfigReport /> }
  ]
};
const EditDeveloperRoutes = {
  path: "developer/:developerId",
  element: protectedDeveloperLayout,
  children: [
    { index: true, element: <Navigate to="package" /> },
    { path: "package", element: <DeveloperPackage /> },
    {
      path: "package/:id",
      element: <LayoutSetDeveloperPackage />,
      children: [
        { index: true, element: <Navigate to="loan" /> },
        { path: "loan", element: <SetDeveloperPackageLoan /> },
        { path: "customer", element: <SetDeveloperPackageCustomer /> },
        { path: "loan-status", element: <SetDeveloperPackageLoanStatus /> },
        { path: "report", element: <SetDeveloperPackageReport /> }
      ]
    },
    { path: "user", element: <SetDeveloperUser /> },
    { path: "edit", element: <SetDeveloperEdit /> },
    { path: "master-setting/province", element: <MasterSetting1Province path="layout.admin.sider.masterSettingProvince" db="province" /> },
    { path: "master-setting/district", element: <MasterSetting2District path="layout.admin.sider.masterSettingDistrict" db="district" /> },
    {
      path: "master-setting/sub-district",
      element: <MasterSetting3SubDistrict path="layout.admin.sider.masterSettingSubDistrict" db="subDistrict" />
    },
    { path: "master-setting/prefix", element: <MasterSetting4Prefix path="layout.admin.sider.masterSettingPrefix" db="prefix" /> },
    { path: "master-setting/gender", element: <MasterSetting5Gender path="layout.admin.sider.masterSettingGender" db="gender" /> },
    {
      path: "master-setting/card-type",
      element: <MasterSetting6CardType path="layout.admin.sider.masterSettingCardType" db="cardType" />
    },
    {
      path: "master-setting/nationality",
      element: <MasterSetting7Nationality path="layout.admin.sider.masterSettingNationality" db="nationality" />
    },
    {
      path: "master-setting/married-status",
      element: <MasterSetting8MarriedStatus path="layout.admin.sider.masterSettingMarriedStatus" db="marriedStatus" />
    },
    {
      path: "master-setting/education",
      element: <MasterSetting9Education path="layout.admin.sider.masterSettingEducation" db="education" />
    },
    {
      path: "master-setting/occupation",
      element: <MasterSetting10Occupation path="layout.admin.sider.masterSettingOccupation" db="occupation" />
    },
    { path: "master-setting/position", element: <MasterSetting11Position path="layout.admin.sider.masterSettingPosition" db="position" /> },
    { path: "master-setting/business", element: <MasterSetting12Business path="layout.admin.sider.masterSettingBusiness" db="business" /> },
    { path: "master-setting/welfare", element: <MasterSetting13Welfare path="layout.admin.sider.masterSettingWelfare" db="welfare" /> },
    { path: "master-setting/bureau", element: <MasterSetting14Bureau path="layout.admin.sider.masterSettingBureau" db="bureau" /> },
    { path: "master-setting/bank", element: <MasterSetting15Bank path="layout.admin.sider.masterSettingBank" db="bank" /> },
    {
      path: "master-setting/loan-status-developer",
      element: <MasterSetting16LoanStatus path="layout.admin.sider.masterSettingLoanStatus" db="loanStatus" />
    },
    {
      path: "master-setting/collateral",
      element: <MasterSetting17Collateral path="layout.admin.sider.masterSettingCollateral" db="collateral" />
    },
    { path: "master-setting/country", element: <MasterSetting18Country path="layout.admin.sider.masterSettingCountry" db="country" /> },
    {
      path: "master-setting/relationship",
      element: <MasterSetting19Relationship path="layout.admin.sider.masterSettingRelationship" db="relationship" />
    }
  ]
};

const AuthRoutes = {
  path: "/auth",
  element: <AuthLayout />,
  children: [
    { element: <Navigate to="login" />, index: true },
    { path: "login", element: <LoginForm /> }
  ]
};

const ThemeRoutes = () => {
  return useRoutes([MainRoutes, AuthRoutes, EditDeveloperRoutes]);
};

export default ThemeRoutes;
