import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const useLocationPathname = () => {
  const {pathname} = useLocation()
  let locationPathname = pathname

  if(locationPathname.slice(locationPathname.length -1) === '/') {  
    locationPathname = locationPathname.slice(0,locationPathname.length -1)
  }

  return useMemo(() => {
    return  {locationPathname }
    
  }, [locationPathname]);
};

export default useLocationPathname;
